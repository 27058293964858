// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-accesibilidad-index-js": () => import("./../../../src/pages/accesibilidad/index.js" /* webpackChunkName: "component---src-pages-accesibilidad-index-js" */),
  "component---src-pages-blogs-categorias-index-js": () => import("./../../../src/pages/blogs/categorias/index.js" /* webpackChunkName: "component---src-pages-blogs-categorias-index-js" */),
  "component---src-pages-blogs-detalle-index-js": () => import("./../../../src/pages/blogs/detalle/index.js" /* webpackChunkName: "component---src-pages-blogs-detalle-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-busqueda-index-js": () => import("./../../../src/pages/busqueda/index.js" /* webpackChunkName: "component---src-pages-busqueda-index-js" */),
  "component---src-pages-citas-medicas-index-js": () => import("./../../../src/pages/citas-medicas/index.js" /* webpackChunkName: "component---src-pages-citas-medicas-index-js" */),
  "component---src-pages-default-404-js": () => import("./../../../src/pages/default404.js" /* webpackChunkName: "component---src-pages-default-404-js" */),
  "component---src-pages-detalleservicioenlinea-index-js": () => import("./../../../src/pages/detalleservicioenlinea/index.js" /* webpackChunkName: "component---src-pages-detalleservicioenlinea-index-js" */),
  "component---src-pages-empresas-index-js": () => import("./../../../src/pages/empresas/index.js" /* webpackChunkName: "component---src-pages-empresas-index-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapa-web-index-js": () => import("./../../../src/pages/mapa-web/index.js" /* webpackChunkName: "component---src-pages-mapa-web-index-js" */),
  "component---src-pages-noticias-categorias-index-js": () => import("./../../../src/pages/noticias/categorias/index.js" /* webpackChunkName: "component---src-pages-noticias-categorias-index-js" */),
  "component---src-pages-noticias-detalle-index-js": () => import("./../../../src/pages/noticias/detalle/index.js" /* webpackChunkName: "component---src-pages-noticias-detalle-index-js" */),
  "component---src-pages-noticias-index-js": () => import("./../../../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-personas-index-js": () => import("./../../../src/pages/personas/index.js" /* webpackChunkName: "component---src-pages-personas-index-js" */),
  "component---src-pages-plantillas-index-js": () => import("./../../../src/pages/plantillas/index.js" /* webpackChunkName: "component---src-pages-plantillas-index-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-proveedores-index-js": () => import("./../../../src/pages/proveedores/index.js" /* webpackChunkName: "component---src-pages-proveedores-index-js" */),
  "component---src-pages-servicio-en-linea-index-js": () => import("./../../../src/pages/servicio_en_linea/index.js" /* webpackChunkName: "component---src-pages-servicio-en-linea-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

